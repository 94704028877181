* {
  margin: 0;
}

body {
  height: 100vh;
}

#root {
  height: 100%;
}

.h-100 {
  height: 100%;
}

.box-shadow {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
}

.box-shadow-dark {
  box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.3);
}

.border-none {
  border: none !important;
}

.w-100 {
  width: 100%;
}
.text-bold {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
.mr-1 {
  margin-right: 20px;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
